<template>
  <div class="search">
    <div class="container">
      <h5>Search</h5>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { defineComponent, computed, reactive } from "vue";
import { useHead } from "@vueuse/head";

export default defineComponent({
  name: "search",
  components: {},
  setup() {
    const siteData = reactive({
      title: `E commerce | Search`,
      description: `My beautiful website`,
    });

    useHead({
      // Can be static or computed
      title: computed(() => siteData.title),
      meta: [
        {
          name: `description`,
          content: computed(() => siteData.description),
        },
      ],
    });
  },
});
</script>
<style>
.search {
  min-height: 100vh;
  margin-top: 55px;
  margin-bottom: 55px;
}
</style>
